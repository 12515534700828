$btn-close-color: #d3af7b;

@import "node_modules/bootstrap/scss/bootstrap";

.document-content {
  .row > * {
    width: unset !important;
  }
}

.panel-content {
  .row > * {
    width: unset !important;
  }
}

.z-index-0 {
  z-index: 0 !important;
}

.modal-title {
  color: #1e3051 !important;
}
.accordion {
  .card{
    border: unset !important;
    background-color: unset !important;
    margin-left: -15px;
    margin-right: -15px;
    .card-header {
      padding: 0px !important;
    }
    .card-body {
      margin-top: 1px;
      padding: 0px !important;
    }
  }
}
.section-title {
  padding: 0px !important;
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.container {
  max-width: none;
}
.modal-backdrop {
  z-index: 0;
}
.modal-backdrop.show {
  opacity: 0.85 !important;
  background-color: #f7f7f7;
}
.modal-dialog {
  margin: 100px auto !important;
  max-width: 600px;
  .modal-content {
    box-sizing: content-box;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 12px 22px rgba(30,48,81,.06);
    padding: 50px;
    width: auto;
    .modal-header {
      padding: unset;
      border-bottom: unset;
      .btn-close {
        &:focus {
          box-shadow: none;
        }
        transition: all .2s cubic-bezier(.215,.61,.355,1);
        position: relative;
        opacity: 1;
        top: -18px;
        @media (min-width: 631px) {
          top: -36px;
          right: -33px;
        }
      }
    }
    .modal-body {
      padding-left: 0px;
      padding-right: 0px;
    }
    .modal-footer {
      padding: unset;
      border-top: unset;
    }
  }
}

.no-wrap-content-box {
  box-sizing: content-box;
  flex-wrap: nowrap;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}

.fa-asterisk {
  margin-right: 4px;
}

.form-horizontal .control-label {
  text-align: left;
}

.form-switch {
  input[type="radio"], input[type="checkbox"] {
    border-color: rgba(0, 0, 0, 0.25);
    border-style: solid;
    border-width: 1px;
    height: 16px;
  }
}

input[type="radio"], input[type="checkbox"] {
  border: 0;
  width: 16px;
  height: 16px;
}

@include medium-screen() {
  input[type="radio"], input[type="checkbox"] {
    width: 18px;
    height: 18px;
  }
}
@include large-screen() {
  input[type="radio"], input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
}
@include larger-screen() {
  input[type="radio"], input[type="checkbox"] {
    width: 22px;
    height: 22px;
  }
}

/**
 * Tooltip Styles
 */
.tooltip-inner {
  max-width: 100%;
  color: $blue;
  text-align: center;
  padding: 8px 10px 10px;
  border-radius: 5px;
  background-color: $gray-lighter;
  display: inline-block;
  font-size: $fs-smallest;
  font-weight: $fw-regular;
  line-height: 1.2;
}

.tooltip-arrow {
  border-top-color: $gray-lighter !important;
}

@keyframes slideInDown {
  from {
    opacity: 1;
    margin-top: 100%;
    width: 300%;
  }

  to {
    opacity: 0;
    margin-top: 0%;
    width: 100%;
  }
}

.fade.show {
  opacity: 1;
  display: inherit;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top {
  margin-top: 0;
  margin-left: 0;
}

.btn {
  line-height: 24px;
}

a.list-group-item, a.list-group-item:hover, a.list-group-item:focus {
  background-color: $light-gray;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family;
  line-height: 1;
}

input:disabled {
  cursor: not-allowed !important;
}

.nav-link {
  color: unset;
  &:hover {
    color: unset;
  }
  &:focus {
    color: unset;
  }
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  .form-check {
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  // Inline-block all the things for "inline"
  .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  // Allow folks to *not* use `.form-group`
  .form-control {
    display: inline-block;
    width: auto; // Prevent labels from stacking above inputs in `.form-group`
    vertical-align: middle;
  }

  // Make static controls behave like regular ones
  .form-control-plaintext {
    display: inline-block;
  }

  .input-group {
    width: auto;
  }

  // Remove default margin on radios/checkboxes that were used for stacking, and
  // then undo the floating of radios and checkboxes to match.
  .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-check-label {
    margin-bottom: 0;
  }
}

.no-transform {
  transform: none !important;
}

.form-control {
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  border: 1px solid $gray-lighter;
  color: $blue-darker;
  box-shadow: 0 0 0 0 $gray-light inset;
  transition: all $trans;
  font-size: $fs-medium;
  line-height: 16px;
  padding: 0 15px;
  height: 40px;
  &:focus {
    border-color: $gray-light;
    box-shadow: 0 0 0 1px $gray-light inset;
  }
}

.form-group {
  margin-bottom: 20px;
  label {
    font-size: $fs-medium;
    font-weight: $fw-medium;
    color: $blue;
    margin-bottom: 8px;
    letter-spacing: 0;
  }
}

.form-select {
  font-size: $fs-medium;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $gray-light;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $gray-light;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $gray-light;
}
:-moz-placeholder { /* Firefox 18- */
  color: $gray-light;
}

p {
  font-size: $fs-medium;
}

strong {
  font-weight: $fw-bold;
}

.btn {
  &:not(.toggle):not(.toggle-handle):not(.toggle-off):not(.toggle-on):not(.toggle-group){
    font-size: $fs-small;
    line-height: 14px;
    height: 40px;
    border-width: 2px;
    border-radius: 20px;
    padding: 10px 20px 12px;
    color: $gold;
    border-color: $gold-lightest;
    transition: all $trans;
    background-color: transparent;
    &.btn-icon {
      span {
        display: block;
        float: left;
        position: relative;
        top: -9px;
        left: -8px;
        margin-right: -3px;
        svg {
          * {
            stroke: $gold;
            transition: stroke $trans;
          }
        }
      }
      &:hover, &:active, &:focus {
        span svg * {
          stroke: $white;
        }
      }
    }
    &.btn-icon-single {
      padding: 10px 11px 12px;
      width: 40px;
      span {
        display: block;
        float: left;
        position: relative;
        top: -8px;
        left: -9px;
        margin-right: -3px;
        svg {
          * {
            stroke: $gold-lighter;
            transition: stroke $trans;
          }
        }
      }
      &:hover, &:active, &:focus {
        span svg * {
          stroke: $white;
        }
      }
    }
    &:hover, &:active, &:focus {
      color: $white;
      border-color: $gold;
      background-color: $gold;
      box-shadow: none;
    }
    &.btn-primary {
      color: $white;
      border-color: $gold;
      background-color: $gold;
      &.btn-icon {
        span {
          svg {
            * {
              stroke: $gold-lightest;
            }
          }
        }
        &:hover, &:active, &:focus {
          span svg * {
            stroke: $white;
          }
        }
      }
      &:hover, &:active, &:focus {
        border-color: $gold-dark;
        background-color: $gold-dark;
        box-shadow: none;
      }
    }
  }
}

button:focus {
  outline: 0 !important;
}

a {
  color: $blue-darker;
  transition: color $trans;
  text-decoration: none;
  outline: none;
  &:hover {
    color: $blue-darkest;
    text-decoration: none;
  }
  &:hover, &:active, &:focus, &:visited {
    outline: none;
  }
}

.link {
  color: $gold;
  display: inline-block;
  padding-bottom: 8px;
  font-weight: $fw-bold;
  font-size: $fs-small;
  position: relative;
  line-height: 1;
  cursor: pointer;
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
  }
  &:before {
    background-color: #f1e7d7;
  }
  &:after {
    background-color: #D3AF7B;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform 200ms ease-in-out;
  }
  &:hover:after {
    transform: scale(1);
    transform-origin: left center;
  }
  &:hover, &:focus{
    color: $gold;
  }
}

body {
  line-height: 1;
}

table {
  max-width:50wv;
}

.datepicker.dropdown-menu {
  border-color: $gray-lighter !important;
  box-shadow: 0 10px 15px $gray-shadow-big !important;
  font-family: $font-family !important;
  color: $blue-darker;
  font-size: $fs-small;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  border-top-color: $gray-lighter;
}
.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background-color: $blue-darker;
  color: $white;
}
.datepicker table tr td.today:hover, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today:focus, .datepicker table tr td.today:hover:focus, .datepicker table tr td.today.disabled:focus, .datepicker table tr td.today.disabled:hover:focus, .datepicker table tr td.today:active, .datepicker table tr td.today:hover:active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td.today, .open .dropdown-toggle.datepicker table tr td.today:hover, .open .dropdown-toggle.datepicker table tr td.today.disabled, .open .dropdown-toggle.datepicker table tr td.today.disabled:hover {
  background-color: $blue-dark;
  color: $white;
}
.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active:focus, .datepicker table tr td.active:hover:focus, .datepicker table tr td.active.disabled:focus, .datepicker table tr td.active.disabled:hover:focus, .datepicker table tr td.active:active, .datepicker table tr td.active:hover:active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td.active, .open .dropdown-toggle.datepicker table tr td.active:hover, .open .dropdown-toggle.datepicker table tr td.active.disabled, .open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
  background-color: $gold;
  color: $white;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background-color: $gold;
  color: $white;
}
:not(.form-switch) > input[type='checkbox'] {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  width: 18px;
  height: 18px;
  margin: 0 0 0 0;
  display: inline-block;
  position: relative;
  cursor:pointer;
  outline: none !important;
  &:focus, &:active {
    outline: none !important;
  }
  &:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: transparent;
    transition: background-color $trans;
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 1;
  }
  &:after {
    content:"";
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    position: absolute;
    cursor: pointer;
    height: 20px;
    width: 20px;
    left:-1px;
    top:-1px;
    font-size:15px;
    background: $white;
    border: 2px solid $gray-lighter;
    border-radius: 2px;
    color: $blue;
    font-family: sans-serif;
    transition: border-color $trans;
  }
  &:checked:before {
    background-color: $blue-darker;
  }
  &:hover:after {
    border-color: $gray-light;
    color: $blue-dark;
  }
  & + label {
    line-height: 20px;
    font-weight: $fw-medium;
  }
}

.text-primary {
  color: $gold !important;
}
