@import 'tippy.js/dist/tippy.css';
@import 'src/assets/scss/modules/colors';
@import 'src/assets/scss/modules/fonts';

.tippy-box {
  background-color: $blue-darkest;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  border-radius: 10px;
  max-width: 600px !important;
  .tippy-content {
    padding: 5px 10px;
    font-size: $extra-small-text !important;
    * {
      color: white;
      font-size: $extra-small-text !important;
    }
    h2, h1 {
      font-size: $large-text !important;
      * {
        font-size: $large-text !important;
      }
    }
  }
}
